import {
  FETCH_USER,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAILED,
  USER_SIGN_IN,
  USER_SIGN_IN_FAILED,
  USER_SIGN_OUT,
  CLEAR_LOGIN_ERROR,
  REQUEST_OTP,
  REQUEST_OTP_SUCCESS,
  REQUEST_OTP_FAILED,
  UPDATE_USER_WALLET_HISTORY,
  SEND_RESET_EMAIL,
  SEND_RESET_EMAIL_FAILED,
} from "../store/types";

import store from '../store/store';
import { firebase } from '../config/configureFirebase';
import { onValue, update, set, off } from "firebase/database";
import { onAuthStateChanged, signInWithCredential, signInWithPopup, signOut, sendPasswordResetEmail, signInWithEmailAndPassword, signInWithCustomToken } from "firebase/auth";
import { uploadBytesResumable, getDownloadURL } from "firebase/storage";
import base64 from 'react-native-base64';
import AccessKey from '../other/AccessKey';

export const fetchUser = () => (dispatch) => {
  const {
    auth,
    config,
    singleUserRef
  } = firebase;

  dispatch({
    type: FETCH_USER,
    payload: null
  });
  onAuthStateChanged(auth, user => {
    if (user) {
      onValue(singleUserRef(user.uid), async snapshot => {
        if (snapshot.val()) {
          let profile = snapshot.val();
          profile.uid = user.uid;
          dispatch({
            type: FETCH_USER_SUCCESS,
            payload: profile
          });
        } else {
          let data = {
            uid: user.uid,
            mobile: '',
            email: '',
            firstName: '',
            lastName: '',
            verifyId: ''
          };

          if (user.providerData.length == 0 && user.email) {
            data.email = user.email;
          }
          if (user.providerData.length > 0 && user.phoneNumber) {
            data.mobile = user.phoneNumber;
          }
          if (user.providerData.length > 0) {
            const provideData = user.providerData[0];
            if (provideData == 'phone') {
              data.mobile = provideData.phoneNumber;
            }
            if (provideData.providerId == "google.com" || provideData.providerId == 'apple.com') {
              if (provideData.email) {
                data.email = provideData.email;
              }
              if (provideData.phoneNumber) {
                data.mobile = provideData.phoneNumber;
              }
              if (provideData.displayName) {
                if (provideData.displayName.split(" ").length > 0) {
                  data.firstName = provideData.displayName.split(" ")[0];
                  data.lastName = provideData.displayName.split(" ")[1];
                } else {
                  data.firstName = provideData.displayName;
                }
              }
              if (provideData.photoURL) {
                data['profile_image'] = provideData.photoURL;
              }
            }
          }
          if (user.providerData.length > 0 && user.verifyId) {
            data.verifyId = user.verifyId;
          }

          const settings = store.getState().settingsdata.settings;
          let host = window && window.location && settings.CompanyWebsite === window.location.origin ? window.location.origin : `https://${config.projectId}.web.app`;
          let url = `${host}/check_auth_exists`;
          const response = await fetch(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              "Authorization": "Basic " + base64.encode(config.projectId + ":" + AccessKey)
            },
            body: JSON.stringify({ data: JSON.stringify(data) })
          });
          const json = await response.json();
          if (json.uid) {
            dispatch({
              type: FETCH_USER_SUCCESS,
              payload: json
            });
          } else {
            dispatch({
              type: FETCH_USER_FAILED,
              payload: { code: store.getState().languagedata.defaultLanguage.auth_error, message: json.error }
            });
          }
        }
      });
    } else {
      dispatch({
        type: FETCH_USER_FAILED,
        payload: { code: store.getState().languagedata.defaultLanguage.auth_error, message: store.getState().languagedata.defaultLanguage.not_logged_in }
      });
    }
  });
};

// Hàm đăng nhập Zalo
export const zaloLogin = async () => {
  try {
    const zaloOAuthUrl = `https://oauth.zaloapp.com/v4/oa/permission?app_id=471293321379994264&redirect_uri=https%3A%2F%2Falogotaxi.com%2F`;
    const response = await fetch(zaloOAuthUrl, {
      method: 'GET',
    });

    // Xử lý mã trả về từ Zalo
    if (response.ok) {
      const result = await response.json();
      const authCode = result.code;

      // Sử dụng mã authCode để lấy access token từ Zalo
      const tokenResponse = await fetch('https://oauth.zaloapp.com/v3/access_token', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          app_id: '471293321379994264',
          code: authCode,
          secret_key: 'bC8MLxkbJQ2JB5oFGZGD',
          redirect_uri: 'https://oauth.zaloapp.com/v4/oa/access_token',
        }),
      });

      const tokenData = await tokenResponse.json();

      // Lưu trữ token vào hệ thống sau khi nhận được từ Zalo
      if (tokenData.access_token) {
        console.log('Zalo Login Successful', tokenData.access_token);
        // Tiếp tục xử lý đăng nhập với token nhận được từ Zalo
        // Gọi API nội bộ để hoàn thành việc đăng nhập
      } else {
        console.error('Failed to retrieve access token from Zalo');
      }
    } else {
      console.error('Failed to login with Zalo');
    }
  } catch (error) {
    console.error('Error during Zalo login', error);
  }
};

// Các hàm khác như Google, Apple login, etc.
export const googleLogin = (idToken, accessToken) => (dispatch) => {
  const { auth, googleCredential } = firebase;

  dispatch({
    type: USER_SIGN_IN,
    payload: null
  });

  const credential = googleCredential(idToken, accessToken);
  signInWithCredential(auth, credential)
    .then((user) => {
      //OnAuthStateChange takes care of Navigation
    })
    .catch(error => {
      console.log(error);
      dispatch({
        type: USER_SIGN_IN_FAILED,
        payload: error
      });
    });
};

export const appleSignIn = (credentialData) => (dispatch) => {
  const { auth, appleProvider } = firebase;

  dispatch({
    type: USER_SIGN_IN,
    payload: null
  });

  if (credentialData) {
    const credential = appleProvider.credential(credentialData);
    signInWithCredential(auth, credential)
      .then((user) => {
        //OnAuthStateChange takes care of Navigation
      })
      .catch((error) => {
        dispatch({
          type: USER_SIGN_IN_FAILED,
          payload: error
        });
      });
  } else {
    signInWithPopup(auth, appleProvider).then(function (result) {
      signInWithCredential(auth, result.credential)
        .then((user) => {
          //OnAuthStateChange takes care of Navigation
        })
        .catch(error => {
          dispatch({
            type: USER_SIGN_IN_FAILED,
            payload: error
          });
        });
    }).catch(function (error) {
      dispatch({
        type: USER_SIGN_IN_FAILED,
        payload: error
      });
    });
  }
};

export const signOff = () => (dispatch) => {

  const {
    auth,
    singleUserRef,
    walletHistoryRef,
    userNotificationsRef
  } = firebase;

  const uid = auth.currentUser.uid;

  off(singleUserRef(uid));
  off(walletHistoryRef(uid));
  off(userNotificationsRef(uid));

  onValue(singleUserRef(uid), snapshot => {
      if(snapshot.val()){
        const profile = snapshot.val();
        if (profile && profile.usertype === 'driver') {
          update(singleUserRef(uid), {driverActiveStatus:false});
        }
        setTimeout(()=>{
          signOut(auth)
          .then(() => {
            dispatch({
              type: USER_SIGN_OUT,
              payload: null
            });
          })
          .catch(error => {
      
          });
        },2000)
      }
  },{onlyOnce: true});
};

export const updateProfile = (updateData) => async (dispatch) => {

  const {
    auth,
    singleUserRef,
    driverDocsRef,
    driverDocsRefBack,
    verifyIdImageRef
  } = firebase;

  const uid = auth.currentUser.uid;
  
  if (updateData.licenseImage) {
    await uploadBytesResumable(driverDocsRef(uid), updateData.licenseImage);
    updateData.licenseImage = await getDownloadURL(driverDocsRef(uid));
  }
  if (updateData.licenseImageBack) {
    await uploadBytesResumable(driverDocsRefBack(uid),updateData.licenseImageBack);
    updateData.licenseImageBack = await getDownloadURL(driverDocsRefBack(uid));
  }
  if (updateData.verifyIdImage) {
    await uploadBytesResumable(verifyIdImageRef(uid), updateData.verifyIdImage);
    updateData.verifyIdImage = await getDownloadURL(verifyIdImageRef(uid));
  }

  update(singleUserRef(uid), updateData);
};


export const updateProfileImage = (imageBlob) => {

  const {
    auth,
    singleUserRef,
    profileImageRef,
  } = firebase;

  const uid = auth.currentUser.uid;

  uploadBytesResumable( profileImageRef(uid), imageBlob).then(() => {
    imageBlob.close()
    return getDownloadURL(profileImageRef(uid))
  }).then((url) => {
    update(singleUserRef(uid), {
      profile_image: url
    });
  })
};

export const updateWebProfileImage = async (imageBlob) => {

  const {
    auth,
    singleUserRef,
    profileImageRef
  } = firebase;

  const uid = auth.currentUser.uid;

  await uploadBytesResumable(profileImageRef(uid), imageBlob);
  let image = await getDownloadURL(profileImageRef(uid));
  update(singleUserRef(uid), {profile_image: image});

};
export const updateCustomerProfileImage = async (imageBlob, id) => {

  const {
    singleUserRef,
    profileImageRef
  } = firebase;

  const uid = id

  await uploadBytesResumable(profileImageRef(uid), imageBlob);
  let image = await getDownloadURL(profileImageRef(uid));
  update(singleUserRef(uid), {profile_image: image});

};

export const updatePushToken = (token, platform)  => {

  const {
    auth,
    singleUserRef,
  } = firebase;

  const uid = auth.currentUser.uid;

  update(singleUserRef(uid), {
    pushToken: token,
    userPlatform: platform
  });
};

export const clearLoginError = () => (dispatch) => {
  dispatch({
    type: CLEAR_LOGIN_ERROR,
    payload: null
  });
};

export const fetchWalletHistory = () => (dispatch) => {
  const {
    auth,
    walletHistoryRef
  } = firebase;

  const uid = auth.currentUser.uid;

  onValue(walletHistoryRef(uid) , snapshot => {
    const data = snapshot.val(); 
    if(data){
      const arr = Object.keys(data).map(i => {
        data[i].id = i
        return data[i]
      });
      dispatch({
        type: UPDATE_USER_WALLET_HISTORY,
        payload: arr.reverse()
      });
    }
  });
};
export const fetchUserWalletHistory = (userId) => (dispatch) => {
  const {
    auth,
    walletHistoryRef
  } = firebase;

  const uid = userId;

  onValue(walletHistoryRef(uid) , snapshot => {
    const data = snapshot.val(); 
    if(data){
      const arr = Object.keys(data).map(i => {
        data[i].id = i
        return data[i]
      });
      dispatch({
        type: UPDATE_USER_WALLET_HISTORY,
        payload: arr.reverse()
      });
    }else{
      dispatch({
        type: UPDATE_USER_WALLET_HISTORY,
        payload:[]
      });
    }
  });
};

export const sendResetMail = (email) => async (dispatch) => {
  const {
    authRef
  } = firebase;

  dispatch({
    type: SEND_RESET_EMAIL,
    payload: email
  });
  sendPasswordResetEmail(authRef(), email).then(function() {
    console.log('Email send successfuly');
  }).catch(function (error) {
      dispatch({
        type: SEND_RESET_EMAIL_FAILED,
        payload: {code: store.getState().languagedata.defaultLanguage.auth_error, message: store.getState().languagedata.defaultLanguage.not_registred }
      });
  });
};

export const verifyEmailPassword = (email, pass) => async (dispatch) => {
  const {
    authRef
  } = firebase;

  signInWithEmailAndPassword(authRef(), email, pass)
    .then((user) => {
      //OnAuthStateChange takes care of Navigation
    })
    .catch((error) => {
      dispatch({
        type: USER_SIGN_IN_FAILED,
        payload: error
      });
    });
}

export const requestMobileOtp = (mobile) => async (dispatch) => {
  const {
    config
  } = firebase;
  dispatch({
    type: REQUEST_OTP,
    payload: true
  }); 

  const settings = store.getState().settingsdata.settings;
  let host = window && window.location && settings.CompanyWebsite === window.location.origin? window.location.origin : `https://${config.projectId}.web.app`
  let url = `${host}/request_mobile_otp`;
  try{
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ mobile: mobile })
    });
    const result = await response.json();
    if(result.success){
      dispatch({
        type: REQUEST_OTP_SUCCESS,
        payload: true
      });
    }else{
      dispatch({
        type: REQUEST_OTP_FAILED,
        payload: result.error
      });
    }
  }catch(error){
    console.log(error);
  }
}

export const verifyMobileOtp = (mobile, otp) => async (dispatch) => {
  const {
    auth,
    config
  } = firebase;
  const body = {
    mobile: mobile,
    otp: otp
  };
  try{
    const settings = store.getState().settingsdata.settings;
    let host = window && window.location && settings.CompanyWebsite === window.location.origin? window.location.origin : `https://${config.projectId}.web.app`
    let url = `${host}/verify_mobile_otp`;
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body)
    })
    const result = await response.json();
    if(result.token){
      signInWithCustomToken(auth,result.token)
        .then((user) => {
          //OnAuthStateChange takes care of Navigation
        })
        .catch((error) => {
          dispatch({
            type: USER_SIGN_IN_FAILED,
            payload: error
          });
        });
    }else{
      dispatch({
        type: USER_SIGN_IN_FAILED,
        payload: result.error
      });
    }
  }catch(error){
    console.log(error);
    dispatch({
      type: USER_SIGN_IN_FAILED,
      payload: error
    });
  }
}

export const updateAuthMobile = async ( mobile, otp) => {
  const {
    auth,
    config
  } = firebase;

  const uid = auth.currentUser.uid;
  const body = {
    uid: uid,
    mobile: mobile,
    otp: otp
  };

  const settings = store.getState().settingsdata.settings;
  let host = window && window.location && settings.CompanyWebsite === window.location.origin ? window.location.origin : `https://${config.projectId}.web.app`
  let url = `${host}/update_auth_mobile`;
  try{
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body)
    })
    const result = await response.json();
    if(result.success){
      return {success: true}
    }else{
      return {success: false, error: result.error}
    }
  }catch(error){
    return {success: false, error: error}
  }
}
