export const FirebaseConfig = {
	"projectId": "alogotaxi-543c0",
	"appId": "1:487232382280:web:6c471e059a09ddc1493f7d",
	"databaseURL": "https://alogotaxi-543c0-default-rtdb.asia-southeast1.firebasedatabase.app",
	"storageBucket": "alogotaxi-543c0.appspot.com",
	"locationId": "asia-southeast1",
	"apiKey": "AIzaSyC8dWP6PRMRaOvTbigrkSxM5wjJ1BJ22fk",
	"authDomain": "alogotaxi-543c0.firebaseapp.com",
	"messagingSenderId": "487232382280",
	"measurementId": "G-K6XBXKE7FR"
};